<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <v-row no-gutters align="center" justify="center">
      <v-card
        flat
        width="220px"
        height="220px"
        class="bg_color1 pa-2 d-flex align-center justify-center rounded-lg flex-column"
      >
        <v-tooltip>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              absolute
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="$router.go(-1)"
              icon
              style="left: 0px; top: 0px"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
          </template>
          <span>Back</span>
        </v-tooltip>
        <v-avatar size="150">
          <img src="../../assets/img/IconProfile.png" class="obj_fit" />
        </v-avatar>
        <h4
          v-if="!loading && dataSet"
          class="mt-2 mb-1 txt_white text-capitalize"
        >
          {{ dataSet.karyawan.nama }}
        </h4>
        <!-- <h5 v-if="!loading && dataSet" class="txt_white">
          {{ dataSet.karyawan.id }}
        </h5> -->
        <div
          v-if="loading"
          class="d-flex align-center justify-center flex-column"
        >
          <v-skeleton-loader
            class="mt-2 mb-2"
            width="60px"
            height="17px"
            type="image"
          >
          </v-skeleton-loader>
          <!-- <v-skeleton-loader width="100px" height="16px" type="image">
          </v-skeleton-loader> -->
        </div>
      </v-card>
      <v-col cols="12" md="7">
        <v-card
          flat
          width="100%"
          min-height="170px"
          outlined
          elevation="7"
          class="d-flex align-center justify-space-between pa-5 rounded-lg"
        >
          <v-row no-gutters align="center" justify="space-between">
            <v-col cols="12" md="3" class="px-3 py-1">
              <div v-if="!loading && dataSet" class="parent_absensi">
                <div class="text-center" style="height: 60px">
                  <h3>Total Kehadiran</h3>
                </div>
                <h2>{{ dataSet.total_absensi }}</h2>
                <v-progress-linear color="#052633" rounded :value="100" />
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  width="50px"
                  height="50px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="px-3 py-1">
              <div v-if="!loading && dataSet" class="parent_absensi">
                <div class="text-center" style="height: 60px">
                  <h3>Total Hadir</h3>
                </div>
                <h2>{{ dataSet.summary.hadir }}</h2>
                <v-progress-linear color="#106967" rounded :value="100" />
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  width="50px"
                  height="50px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="px-3 py-1">
              <div v-if="!loading && dataSet" class="parent_absensi">
                <div class="text-center" style="height: 60px">
                  <h3>Total Tidak Hadir</h3>
                </div>
                <h2>{{ dataSet.summary["tidak hadir"] }}</h2>
                <v-progress-linear color="#DC3545" rounded :value="100" />
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  width="50px"
                  height="50px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="px-3 py-1">
              <div v-if="!loading && dataSet" class="parent_absensi">
                <div class="text-center" style="height: 60px">
                  <h3>Total Telat</h3>
                </div>
                <h2>{{ dataSet.summary.telat }}</h2>
                <v-progress-linear color="#BD9028" rounded :value="100" />
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  width="50px"
                  height="50px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <v-card flat class="history" color="#CFE1E1">
        <v-row v-if="!loading" no-gutters>
          <v-col cols="12">
            <v-row class="pa-2" no-gutters justify="center">
              <v-col cols="12" md="7" class="px-2">
                <date-picker
                  nav-visibility="hover"
                  ref="calender"
                  v-model="value_calendar"
                  is-expanded
                  title-position="left"
                  class="ma-1"
                  :step="1"
                  :attributes="attrs0"
                  @dayclick="checkValue(false)"
                  color="yellow"
                />
              </v-col>
              <v-col cols="12" md="2" class="px-2">
                <div>
                  <h3>Keterangan:</h3>
                  <div class="mt-2 d-flex align-center">
                    <v-card
                      width="20px"
                      height="20px"
                      flat
                      class="rounded-circle mr-3"
                      color="#106967"
                    >
                    </v-card>
                    <h4 class="text-capitalize not_bold">Hadir</h4>
                  </div>
                  <div class="mt-2 d-flex align-center">
                    <v-card
                      width="20px"
                      height="20px"
                      flat
                      class="rounded-circle mr-3"
                      color="#BD9028"
                    >
                    </v-card>
                    <h4 class="text-capitalize not_bold">Telat</h4>
                  </div>
                  <div class="mt-2 d-flex align-center">
                    <v-card
                      width="20px"
                      height="20px"
                      flat
                      class="rounded-circle mr-3"
                      color="#052633"
                    >
                    </v-card>
                    <h4 class="text-capitalize not_bold">Izin</h4>
                  </div>
                  <div class="mt-2 d-flex align-center">
                    <v-card
                      width="20px"
                      height="20px"
                      flat
                      class="rounded-circle mr-3"
                      color="#DC3545"
                    >
                    </v-card>
                    <h4 class="text-capitalize not_bold">Tidak Hadir</h4>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row no-gutters class="pa-3">
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(1)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flat color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 1 ? 'active_calender' : 'non_active_calender'
                      "
                      nav-visibility="visible"
                      ref="calendar1"
                      title-position="left"
                      :attributes="attrs1"
                    ></calendar>
                    <div
                      :class="month == 1 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(2)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flat color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 2 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar2"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs2"
                    ></calendar>
                    <div
                      :class="month == 2 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(3)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 3 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar3"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs3"
                    ></calendar>
                    <div
                      :class="month == 3 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(4)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 4 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar4"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs4"
                    ></calendar>
                    <div
                      :class="month == 4 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(5)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 5 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar5"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs5"
                    ></calendar>
                    <div
                      :class="month == 5 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(6)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 6 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar6"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs6"
                    ></calendar>
                    <div
                      :class="month == 6 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(7)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 7 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar7"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs7"
                    ></calendar>
                    <div
                      :class="month == 7 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(8)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 8 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar8"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs8"
                    ></calendar>
                    <div
                      :class="month == 8 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(9)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 9 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar9"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs9"
                    ></calendar>
                    <div
                      :class="month == 9 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(10)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 10 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar10"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs10"
                    ></calendar>
                    <div
                      :class="month == 10 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(11)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 11 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar11"
                      :attributes="attrs11"
                      nav-visibility="visible"
                      title-position="left"
                      is-disabled
                    >
                    </calendar>
                    <div
                      :class="month == 11 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div
                  @click="detailCalendar(12)"
                  class="pa-2 d-flex align-center justify-center"
                >
                  <v-card flatc color="transparent" elevation="0">
                    <calendar
                      :class="
                        month == 12 ? 'active_calender' : 'non_active_calender'
                      "
                      ref="calendar12"
                      nav-visibility="visible"
                      title-position="left"
                      :attributes="attrs12"
                    >
                    </calendar>
                    <div
                      :class="month == 12 ? 'hide_active' : 'hide_non_active'"
                    ></div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { NavbarProfile },
  name: "historyAttendence",
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      loading: true,
      dataSet: null,
      value_calendar: new Date(),
      temp_value_calendar: new Date(),
      month: new Date().getMonth(),
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Kehadiran",
          disabled: false,
          href: `/admin/mk/client-attendence/attendence/${this.$route.params.idClient}`,
        },
        {
          text: "Histori Kehadiran",
          disabled: true,
          href: ``,
        },
      ],
      attrs0: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(),
        },
      ],
      attrs1: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},1,1`),
        },
      ],
      attrs2: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},2,1`),
        },
      ],
      attrs3: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},3,1`),
        },
      ],
      attrs4: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},4,1`),
        },
      ],
      attrs5: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},5,1`),
        },
      ],
      attrs6: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},6,1`),
        },
      ],
      attrs7: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},7,1`),
        },
      ],
      attrs8: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},8,1`),
        },
      ],
      attrs9: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},9,1`),
        },
      ],
      attrs10: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},10,1`),
        },
      ],
      attrs11: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},11,1`),
        },
      ],
      attrs12: [
        {
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },
            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          dates: new Date(`${new Date().getFullYear()},12,1`),
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async checkValue(first) {
      // check kalender, ganti tahun atau tidak
      if (this.value_calendar) {
        this.temp_value_calendar = this.value_calendar;
      } else {
        this.value_calendar = this.temp_value_calendar;
      }
      if (first) {
        await this.$refs.calendar1.move({
          month: 1,
          year: this.attrs1[0].dates.getFullYear(),
        });
        await this.$refs.calendar2.move({
          month: 2,
          year: this.attrs2[0].dates.getFullYear(),
        });
        await this.$refs.calendar3.move({
          month: 3,
          year: this.attrs3[0].dates.getFullYear(),
        });
        await this.$refs.calendar4.move({
          month: 4,
          year: this.attrs4[0].dates.getFullYear(),
        });
        await this.$refs.calendar5.move({
          month: 5,
          year: this.attrs5[0].dates.getFullYear(),
        });
        await this.$refs.calendar6.move({
          month: 6,
          year: this.attrs6[0].dates.getFullYear(),
        });
        await this.$refs.calendar7.move({
          month: 7,
          year: this.attrs7[0].dates.getFullYear(),
        });
        await this.$refs.calendar8.move({
          month: 8,
          year: this.attrs8[0].dates.getFullYear(),
        });
        await this.$refs.calendar9.move({
          month: 9,
          year: this.attrs9[0].dates.getFullYear(),
        });
        await this.$refs.calendar10.move({
          month: 10,
          year: this.attrs10[0].dates.getFullYear(),
        });
        await this.$refs.calendar11.move({
          month: 11,
          year: this.attrs12[0].dates.getFullYear(),
        });
        await this.$refs.calendar12.move({
          month: 12,
          year: this.attrs12[0].dates.getFullYear(),
        });
      } else {
        await this.$refs.calendar1.move({
          month: 1,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar2.move({
          month: 2,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar3.move({
          month: 3,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar4.move({
          month: 4,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar5.move({
          month: 5,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar6.move({
          month: 6,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar7.move({
          month: 7,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar8.move({
          month: 8,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar9.move({
          month: 9,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar10.move({
          month: 10,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar11.move({
          month: 11,
          year: this.value_calendar.getFullYear(),
        });
        await this.$refs.calendar12.move({
          month: 12,
          year: this.value_calendar.getFullYear(),
        });
        this.month = this.value_calendar.getMonth() + 1;
      }
    },
    async detailCalendar(item) {
      await this.$refs.calender.move({
        month: item,
        year: this.value_calendar.getFullYear(),
      });
      this.month = item;
    },
    // get data adn cek kehadiran dan diubah formatnya sesuai dengan library yg dipakai
    async fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/absensi/per-karyawan/${this.$route.params.idUser}`,
      };
      await this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.dataSet = data;
          this.loading = false;
          data.data.forEach((history) => {
            for (let i = 0; i < 13; i++) {
              this[`attrs${i}`].push({
                highlight: {
                  style: {
                    background:
                      history.keterangan == "telat"
                        ? "#BD9028"
                        : history.keterangan == "tidak hadir"
                        ? "#DC3545"
                        : history.keterangan == "hadir"
                        ? "#106967"
                        : history.keterangan == "izin"
                        ? "#052633"
                        : "transparent",
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                  },
                  contentStyle: {
                    color:
                      history.keterangan == "hadir" ||
                      history.keterangan == "izin"
                        ? "white"
                        : "black",
                  },
                },
                popover: {
                  label: `Anda ${history.keterangan}`,
                  visibility: "hover",
                  hideIndicator: true,
                },

                dates: new Date(history.tgl_absen),
              });
            }
          });
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
      await this.checkValue(true);
    },
  },
};
</script>

<style scoped>
.history {
  margin-top: 3vh;
  border-radius: 80px 80px 0px 0px;
  width: 100%;
  min-height: 64vh;
  overflow: hidden;
  padding: 4vh 4vh 4vh 4vh;
}
/* mx-2 d-flex align-space-between justify-space-between text-center flex-column */
.parent_absensi {
  margin: 0px 8px 0px 8px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
}
.active_calender {
  background: #e9ecf1;
  border-radius: 12px;
}
.non_active_calender {
  background: white;
  border-radius: 12px;
}

.hide_active {
  top: 0px;
  right: 0px;
  width: 70px;
  height: 40px;
  background-color: #e9ecf1;
  position: absolute;
  z-index: 1;
  margin: 4px;
}
.hide_non_active {
  top: 0px;
  right: 0px;
  width: 70px;
  height: 40px;
  background-color: white;
  position: absolute;
  z-index: 1;
  margin: 4px;
}
</style>
